import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"

import "./blogTemplate.css"
import "./article.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) {
  const { mdx, allJson } = data // data.markdownRemark holds our post data
  const { frontmatter, body } = mdx
  const { older, newer } = pageContext
  const { nodes } = allJson

  return (
    <Layout subtitle="Blog">
      <div className="article-post-container">
        <div className="article-post">
          <span className="blog-title">{frontmatter.title}</span>
          <span className="blog-date">{frontmatter.date}</span>

          <hr/>

          <div className="blog-post-content">
            <MDXRenderer songs={nodes}>{body}</MDXRenderer>
          </div>

          <hr/>

        </div>
      </div>
      <div className="article-nav-container">
         <div className="article-nav-item">{older && <Link to={older.frontmatter.slug}>&lt;Older</Link>}</div>
         <div className="article-nav-item center"><Link to="/blog">Blog</Link></div>
         <div className="article-nav-item right">{newer && <Link to={newer.frontmatter.slug}>Newer&gt;</Link>}</div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $songs: [String]) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order:DESC }) {
      edges{
        node{
          frontmatter {
            slug
          }
        }
      }
    }
    allJson(filter: { song: { in: $songs } }) {
      nodes {
        song
        artist
        title
        filename
        lyrics
      }
    }
  }
`
